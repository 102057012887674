<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit Property Promotion</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <!-- Property Promotion Category -->
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Category Property Promotion (*)</span>
            <v-select
              label="name"
              :options="propertyPromotionCategoryOptions"
              :reduce="category => category.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.property_promotion_category_id"
            />
          </vs-col>

          <!-- Property Promotion Title -->
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Title (*)"
              v-model="payload.title"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('title') }}</span>
          </vs-col>

          <!-- Property Promotion Subtitle -->
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Subtitle (*)"
              v-model="payload.subtitle"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('subtitle') }}</span>
          </vs-col>

          <!-- Minimum Discount -->
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              type="number"
              min="0"
              max="100"
              class="w-full"
              label="Minimum Discount (*)"
              v-model="payload.min_discount"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('min_discount') }}</span>
          </vs-col>

          <!-- Maximum Discount -->
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              type="number"
              min="0"
              max="100"
              class="w-full"
              label="Maximum Discount (*)"
              v-model="payload.max_discount"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('max_discount') }}</span>
          </vs-col>

          <!-- Description -->
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Description</span>
            <vs-textarea
              class="w-full"
              v-model="payload.description"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('description') }}</span>
          </vs-col>

          <!-- Book Start Date -->
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Book Start Date</span>
            <flat-pickr
              class="w-full"
              v-model="payload.book_start_date"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("book_start_date")
            }}</span>
          </vs-col>

          <!-- Book End Date -->
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Book End Date</span>
            <flat-pickr
              class="w-full"
              :config="bookEndDateConfig"
              v-model="payload.book_end_date"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("book_end_date")
            }}</span>
          </vs-col>

          <!-- Stay Start Date -->
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Stay Start Date</span>
            <flat-pickr
              class="w-full"
              v-model="payload.stay_start_date"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("stay_start_date")
            }}</span>
          </vs-col>

          <!-- Stay End Date -->
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Stay End Date</span>
            <flat-pickr
              class="w-full"
              :config="stayEndDateConfig"
              v-model="payload.stay_end_date"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("stay_end_date")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from '@/axios'
import vSelect from 'vue-select'

// Store Module
import modulePropertyPromotionManagement from "@/store/property-promotion-management/modulePropertyPromotionManagement.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "PropertyPromotionEdit",

  metaInfo: {
    title: "Edit Property Promotion",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect,
    flatPickr
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  watch: {
    payload : {
      handler(payload) {
        this.bookEndDateConfig = {
          minDate: payload.book_start_date
        }
        this.stayEndDateConfig = {
          minDate: payload.stay_start_date
        }
      },

      deep: true
    }
  },

  data: () => ({
    payload: {
      property_promotion_category_id: "",
      title: "",
      subtitle: "",
      description: "",
      book_start_date: "",
      book_end_date: "",
      stay_start_date: "",
      stay_start_date: "",
      min_discount: 0,
      max_discount: 0
    },

    loading: true,

    // Options
    propertyPromotionCategoryOptions: [],

    // flatPickr config
    bookEndDateConfig: {},
    stayEndDateConfig: {},
  }),

  methods: {
    getPropertyPromotionCategories() {
      axios
        .get("property-promotion-category")
        .then(({ data: res }) => {
          this.propertyPromotionCategoryOptions = res.data.map(category => {
            return {
              id: category.id,
              name: category.name
            }
          });
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    getData () {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('propertyPromotionManagement/fetchPropertyPromotion', this.decryptedID)
                 .then(({data: res}) => {
                   this.payload.property_promotion_category_id = res.data.property_promotion_category.id
                   this.payload.title = res.data.title
                   this.payload.subtitle = res.data.subtitle
                   this.payload.min_discount = res.data.min_discount
                   this.payload.max_discount = res.data.max_discount
                   this.payload.description = res.data.description !== null ? res.data.description : ''
                   this.payload.book_start_date = res.data.book_start_date !== null ? res.data.book_start_date : ''
                   this.payload.book_end_date = res.data.book_end_date !== null ? res.data.book_end_date : ''
                   this.payload.stay_start_date = res.data.stay_start_date !== null ? res.data.stay_start_date : ''
                   this.payload.stay_end_date = res.data.stay_end_date !== null ? res.data.stay_end_date : ''
                 })
                 .catch(err => this.$catchErrorResponse(err))
                 .finally(() => {
                   this.loading = false
                   this.$vs.loading.close()
                 })
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set('property_promotion_category_id', this.payload.property_promotion_category_id)
      formData.set("title", this.payload.title);
      formData.set("subtitle", this.payload.subtitle);
      formData.set("min_discount", this.payload.min_discount);
      formData.set("max_discount", this.payload.max_discount);
      this.payload.description !== '' ? formData.set('description', this.payload.description) : ''
      this.payload.book_start_date !== '' ? formData.set('book_start_date', this.payload.book_start_date) : ''
      this.payload.book_end_date !== '' ? formData.set('book_end_date', this.payload.book_end_date) : ''
      this.payload.stay_start_date !== '' ? formData.set('stay_start_date', this.payload.stay_start_date) : ''
      this.payload.stay_end_date !== '' ? formData.set('stay_end_date', this.payload.stay_end_date) : ''
      formData.set("_method", 'PUT');

      this.$store
        .dispatch("propertyPromotionManagement/updatePropertyPromotion", {
          id: this.decryptedID,
          body: formData
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/property-promotions");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  mounted() {
    //
  },

  created() {
    if (!modulePropertyPromotionManagement.isRegistered) {
      this.$store.registerModule("propertyPromotionManagement", modulePropertyPromotionManagement);
      modulePropertyPromotionManagement.isRegistered = true;
    }

    this.getPropertyPromotionCategories()
    this.getData()
  }
};
</script>

<style></style>
